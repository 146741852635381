import { Button, Form, message, Input } from 'antd';

import dayjs from 'dayjs';
import { categorySelectOption } from '../edit/categorySelectOption';
import { useBuildingConfig } from '../useBuildingConfig';
import { constantMonthlyStatement } from '../edit/categorySelectOption';
import { TrpcBase } from '../../../utils/trpc';

const { TextArea } = Input;
export default ({
	remark,
	transactionOrderNumber,
	leaseData,
	amount,
}: {
	remark: string;
	transactionOrderNumber: string;
	amount: string;
	leaseData: any[];
}) => {
	const { currentBuildingConfig } = useBuildingConfig();
	const [form] = Form.useForm();
	const addRemarkApi = TrpcBase.apartment.UpdateWechatBill.useMutation();

	const mySuggestion = leaseData?.filter((i) => {
		return Math.abs(i?.amount?.toNumber() ?? 0) === Number.parseFloat(amount);
	});

	return (
		<div className="flex justify-center items-center" style={{ width: 200 }}>
			<Form
				form={form}
				initialValues={{ remark: remark }}
				onFinish={async (v) => {
					try {
						await addRemarkApi.mutateAsync({
							transactionOrderNumber: transactionOrderNumber,
							remark: v.remark,
						});

						// message.success('updated success');
					} catch (e: any) {
						e?.message && message.error(e.message);
					}
				}}
			>
				<Form.Item name={'remark'} noStyle>
					<TextArea
						placeholder="remark"
						onChange={() => {
							form.submit();
						}}
					/>
				</Form.Item>
				<div className="flex flex-col">
					{leaseData.length > 0 &&
						mySuggestion
							// ?.filter(
							//   (a) =>
							//     `${dayjs(a.checkInTime).format('YYYY年MM月')} ${
							//       currentBuildingConfig?.buildingName
							//     } ${a.roomNumber} ${
							//       categorySelectOption.find((i) => i.value === a.category)?.label
							//     } https://i.ihook.center/apartment/print?uuid=${a?.uuid}` !== remark,
							// )
							?.map((a) => {
								const sentence = `${
									a.category === constantMonthlyStatement ? '补交 ' : ''
								}${dayjs(a.checkInTime).format('YYYY年MM月')} ${
									currentBuildingConfig?.buildingName
								} ${a.roomNumber} ${
									categorySelectOption.find((i) => i.value === a.category)
										?.label
								} https://i.ihook.center/apartment/print?uuid=${a?.uuid}`;

								return (
									<Button
										disabled={sentence === remark}
										onClick={() => {
											form.setFieldValue('remark', sentence);

											setTimeout(() => {
												form.submit();
											}, 50);
										}}
										type="link"
									>
										{dayjs(a.checkInTime).format('YYYY年MM月')} {a.roomNumber}
									</Button>
								);
							})}
				</div>
			</Form>
		</div>
	);
};
